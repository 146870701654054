import React from "react";
import Inscribe from "./Inscribe";

const postData = [
    {
        codigo: 4,
        bajada: "Técnicas para facilitar la Organización Emocional y Conductual de Personas Autistas en el contexto educativo ",
        titulo: "Transformación Profesional para la educación de Personas Autistas",
        docente: 'Emilia Godoy,Dolores Sánchez, José Cuevas ',
        duracion: "4",
        modalidad: 'Curso práctico. Técnicas Profesionales en base a casos reales de escuela : Emilia Godoy. Método OJEA: Dolores Sanchez. SILOGICE, Transformación a través del lenguaje : José Cuevas',
        semanas: 0,
        inicio: '02-04-2024 y 09-04-2024',
        fin: '',
        dirigido: 'Profesionales de la Educación Diferencial',
        descripcion: 'Dos Talleres',
        objetivo: 'Al finalizar la Ruta de Aprendizaje el/la Docente será capaz de: a) Trabajar con niñ@s autistas en aula escolar b) Crear las adecuaciones requeridas para su grupo de escolares autistas c) Comprender y aplicar con efectividad las técnicas de regulación emocional en Aula Autista ',
        contenidos: 'Planificación Curricular para el autismo. El lenguaje como generador de Efectividad Docente. Análisis y Adecuación del Currículum para el Aula Autista',
        metodologia: '100% práctica y de mentoría personal, en base a la teorías Educacionales Aplicables y Currículum Establecido. a) 48 horas de Experiencia en Aula Caminantes b) 8 Horas de mentoría personal con las Guías Docentes Caminantes',
        certificados: 'Certificado de Participación.',
        imgSrc: './Educadora.jpg',
        vigente: true,
    },
    {
        codigo: 1,
        bajada: "Decidiendo una Institución Educacional para mi hijo o hija autista. Alternativas actuales. Criterios de decisión. ",
        titulo: "Autismo y Necesidades Especiales. Sistema escolar regular en Chile",
        docente: 'Dolores Sanchez, Emilia Godoy, Constanza Lagos, Tamara Navarro, Estefanía Araya, Pabla Álvarez',
        duracion: '2',
        modalidad: 'Online',
        semanas: 0,
        inicio: '18/01/2024 19:30 horas ',
        fin: '',
        dirigido: 'Padres y Cuidadores',
        descripcion: 'Taller conversatorio y testimonial para padres. Dirigido por Padres y Profesionales',
        objetivo: 'Orientaciones para el proceso de escolarización de niños y niñas con autismo',
        contenidos: 'Objetivos de la escolarización regular. El autismo en el aula regular. Escenario legal en el sistema escolar chileno',
        metodologia: 'Exposición y foro de respuesta a consultas',
        certificados: 'Certificado de Participación',
        imgSrc: './Escolar.jpg',
        vigente: true,
    },
    {
        codigo: 2,
        bajada: "Diseño de talleres grupales de Terapia Ocupacional para niños y niñas Autistas. Adaptaciones para el logro terapéutico.",
        titulo: "Terapia Ocupacional Grupal a niños y niñas Autistas",
        docente: 'Estefanía Araya, Dolores Sanchez',
        duracion: '24',
        modalidad: 'Práctica en Centro Caminantes y Mentoría Personal',
        semanas: 8,
        inicio: 'Inicio 07/12/2023',
        fin: 'Término 25/01/2024',
        dirigido: 'Terapeutas Ocupacionales',
        descripcion: '24 horas de práctica, 3 horas cada semana, durante 8 semanas con grupo de niñas y niños autistas',
        objetivo: 'Al finalizar la Ruta de Aprendizaje el/la Profesional será capaz de: a) Trabajar con niñ@s autistas en terapia grupal b) Planificar una sesión grupal para grupo de niñ@s autistas c) Realizar retroalimentación y redactar informes de terapia coherentes al objetivo y a lospropósitos terapéuticos integrales del niño, escuela, familia/cuidadores',
        contenidos: 'Planificación Terapéutica para el autismo. El lenguaje como generador de acción terapéutica. Incorporación del entorno extra-sesión en la praxis terapéutica',
        metodologia: '100% práctica y de mentoría personal, en base a la teoría de Terapia Ocupacional. a) 8 horas de Experiencia en espacio de trabajo con niños y niñas en taller de TerapiaOcupacional b) Mentoría personal con la Docente antes y después de cada Experiencia',
        certificados: 'Certificado de Competencia en Atención grupal Autismo y Certificado de Experiencia Práctica en Atención grupal Autismo',
        imgSrc: './TO.jpg',
        vigente: true,
    },


]


export default function Programas() {
    return (
        <div id="certificaciones" className="flex flex-row flex-wrap flex-grow gap-4 justify-around">
            {postData.map((items, i) => (
                <div key={i} id={items.codigo.toString()}
                    className=" border-2 border-white rounded-lg p-2 
                        flex flex-col gap-2 justify-around lg:w-96  h-full bg-violet-500">
                    <div id="caja"  className="rounded-lg">
                        <div className="rounded-lg py-0" >
                            <img src={items.imgSrc}
                                alt={items.titulo}
                                className="rounded-lg mx-auto h-60 w-full" />
                        </div>
                        <div className="pt-2 bg-transparent font-semibold  text-white rounded-lg px-1 h-20 text-2xl">
                            {items.titulo}
                        </div>
                        <div className=" bg-transparent text-slate-100 rounded-lg px-1 h-40 text-2xl pt-6">
                            {items.bajada}
                        </div>
                        <div className="text-2xl text-white font-semibold pt-6">{'Contenidos'}</div>
                        <div className=" bg-transparent text-white rounded-lg px-1 h-40 text-xl p-2">
                            {items.contenidos}
                        </div>
                        <div className="relative ">
                            <Inscribe parametros={items} nomActividad={items.titulo} />
                        </div>
                    </div>

                </div>
            ))}
        </div>
    );
}



