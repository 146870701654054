import React from 'react';
import Pagina from './components/Pagina'

export default function App() {
  return (
      <Pagina />
      
  )
}

