import React from 'react'
import Programas from "./Programas"
import Footer from './Footer'


export default function Cabecera() {
    return (
        <div id="home-section" className='andres texto-sombra text-center h-window px-2'>
            <img src="./logouniversitea4.png" alt="logo" className='h-48 mx-auto pt-4   ' />
            <img src="./logo.png" alt="Caminantes" className='mx-auto w-96' />
            <div className='mx-auto py-3 text-3xl text-white'>Certificación de Competencias en Autismo</div>
            <div className='text-3xl text-white '>Para profesionales de la Educación, Terapeutas y Familias.</div>
            <div className="text-white text-3xl lg:text-4xl font-semibold pt-2 pb-4 text-center">
                Certificaciones
            </div>
            <Programas />
            <Footer />

        </div>
    )
}


